<template>
<div>
    空空如也~
</div>
</template>
<script>
export default {
    name: 'Home',
    data () {
        return {
        }
    },
    methods: {
    },
    mounted() {
    }
}
</script>
<style scoped lang="scss">
</style>